import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IGFLCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './gfl-character-dynamic.scss';
import { Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IGFLCharacterNodes {
  nodes: IGFLCharacter[];
}

interface IGFLCharacterEntry {
  currentUnit: IGFLCharacterNodes;
}

interface IProps {
  data: IGFLCharacterEntry;
}

const GFLCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-gfl'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}
                  </strong>{' '}
                  character from the <strong>{character.class}</strong> class
                  who wields a weapon from the{' '}
                  <strong>{character.weapon}</strong> category{' '}
                  <strong>
                    (
                    {character.weaponName
                      ? character.weaponName
                      : 'missing data'}
                    )
                  </strong>
                  . She's affiliated with{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.affiliation}
                  </strong>
                  . The character was originally released on{' '}
                  <strong>{character.releaseDate}</strong> in China{' '}
                  {character.releaseDateGlobal ? (
                    <>
                      and they will release on{' '}
                      <strong>{character.releaseDateGlobal}</strong> in Global.
                    </>
                  ) : (
                    <>but their Global release date isn't known yet.</>
                  )}
                </h2>
                <div className="char-intro">
                  {character.description ? (
                    <>{renderRichText(character.description, options)}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build and teams</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats (Max Level)
          </div>
          {character.stats ? (
            <>
              <div className="stats-gfl">
                <Row xs={1} xl={1} xxl={2} className="info-list stats">
                  <Col>
                    <div className="stat-box">
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_atk.png"
                            alt="Stat"
                          />
                          Attack
                        </div>
                        <div className={`details`}>{character.stats.atk}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_def.png"
                            alt="Stat"
                          />
                          Defense
                        </div>
                        <div className={`details`}>{character.stats.def}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_hp.png"
                            alt="Stat"
                          />
                          Health
                        </div>
                        <div className={`details`}>{character.stats.hp}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_crit.png"
                            alt="Stat"
                          />
                          Critical Hit
                        </div>
                        <div className={`details`}>
                          {character.stats.crit_rate}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_stability.png"
                            alt="Stat"
                          />
                          Stability Index
                        </div>
                        <div className={`details`}>{character.stats.home}</div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">Weakness I</div>
                        <div className={`details`}>
                          {character.stats.weakness_1}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="stat-box">
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_atk.png"
                            alt="Stat"
                          />
                          Attack Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.atk_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_def.png"
                            alt="Stat"
                          />
                          Defense Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.def_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_hp.png"
                            alt="Stat"
                          />
                          Health Boost
                        </div>
                        <div className={`details`}>
                          {character.stats.hp_bonus}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_critdmg.png"
                            alt="Stat"
                          />
                          Critical Damage
                        </div>
                        <div className={`details`}>
                          {character.stats.crit_dmg}%
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">
                          <StaticImage
                            src="../images/exilium/icons/stat_mobility.png"
                            alt="Stat"
                          />
                          Action Points
                        </div>
                        <div className={`details`}>
                          {character.stats.mobility}
                        </div>
                      </div>
                      <div className="info-list-row">
                        <div className="category">Weakness II</div>
                        <div className={`details`}>
                          {character.stats.weakness_2}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>stats aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.skills ? (
            <>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xxl={2}>
                  {character.skills.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${character.rarity}`}
                            >
                              {skill.type} Skill
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                              <p className="skill-type">
                                {skill.tags}{' '}
                                {skill.specialTags && (
                                  <>| {skill.specialTags}</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            <p>
                              Range:{' '}
                              <span>
                                {skill.range ? <>{skill.range}</> : <>-</>}
                              </span>
                            </p>
                            <p>
                              AoE:{' '}
                              <span>
                                {skill.aoE ? <>{skill.aoE}</> : <>-</>}
                              </span>
                            </p>
                            <p>
                              {skill.type === 'Ultimate' ? (
                                <>Confectance Cons.</>
                              ) : (
                                <>Cooldown</>
                              )}
                              :{' '}
                              <span>
                                {skill.cooldown ? (
                                  <>{skill.cooldown}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            </p>
                            <p>
                              Stability damage:{' '}
                              <span>
                                {skill.stabilityDamage ? (
                                  <>{skill.stabilityDamage}</>
                                ) : (
                                  <>-</>
                                )}
                              </span>
                            </p>
                          </div>
                          <div className={`raw list skill-description`}>
                            {renderRichText(skill.description, options)}
                          </div>
                          {skill.upgrades && skill.upgrades.length > 0 ? (
                            <>
                              <div className="skill-upgrades">
                                {skill.upgrades.map((upgrade, index2) => {
                                  return (
                                    <p key={index2}>
                                      <span className="level">
                                        Fortification Segment {upgrade.level}:
                                      </span>{' '}
                                      <span
                                        className={`skill-with-coloring`}
                                        dangerouslySetInnerHTML={{
                                          __html: upgrade.effect
                                        }}
                                      />
                                    </p>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="skill-upgrades">
                                <p>This skill has no upgrades.</p>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Neural Helix
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>Neural Helix aren't available yet.</strong> They will be
              added soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.va.en ? character.va.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.va.jpn ? character.va.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.va.cn ? character.va.cn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.va.kr ? character.va.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>images aren't available yet.</strong> They will be added
              soon!
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>review isn't available yet.</strong> It will be added
              soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>pros & cons aren't available yet.</strong> It will be
              added soon!
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>ratings aren't available yet.</strong> It will be added
              soon!
            </p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build and teams
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best build
          </div>{' '}
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>build information aren't available yet.</strong> They will
              be added soon.
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Synergy
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>synergies aren't available yet.</strong> They will be
              added soon.
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Video guides
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>guide videos aren't available yet.</strong>
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default GFLCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' Guide and Build | GFL: Exilium | Prydwen'}
      description={
        'Discover the best builds, teams and detailed guides for ' +
        character.name +
        ' in GFL: Exilium.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulGflCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...GflCharacterFieldsFragment
      }
    }
  }
`;
